import { Text, Alert, Icon, HStack } from '@chakra-ui/react'
import { core } from 'composable/chakra/figma-tokens'
import { useFormat } from 'helpers/hooks/useFormat'
import { GoAlertFill } from 'react-icons/go'
import { HorizontalProductCardProps } from './types'
import { cartlabels } from './utils'
import { DONATION_SKU, GIFT_CARD_SKU } from '../general/constants'

export const OutOfStockAlertMessage = (props: HorizontalProductCardProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const labels = cartlabels(formatMessage)

  const { quantity, variant, onRemove, onChangeQuantity, isLoading } = props
  const isNotEnoughInStock =
    variant?.availableQuantity > 0 && variant?.availableQuantity < quantity ? variant?.availableQuantity : null

  if ([DONATION_SKU, GIFT_CARD_SKU].includes(variant.sku)) {
    return null
  }

  return (
    <Alert
      status={isNotEnoughInStock ? 'warning' : 'error'}
      rounded="md"
      textStyle="body-75"
      borderLeft="4px solid"
      borderLeftColor={isNotEnoughInStock ? core.warning['warning-500'] : core.danger['danger-600']}
      bgColor={isNotEnoughInStock ? core.warning['warning-100'] : core.danger['danger-100']}
    >
      <HStack gap={3}>
        <Icon as={GoAlertFill} boxSize={6} color={core.danger['danger-600']} />
        <Text>
          {labels.outOfStock}
          {'  '}
          <Text
            as="button"
            textDecoration="underline"
            onClick={isLoading ? undefined : onRemove}
            cursor={isLoading ? 'initial' : 'pointer'}
            aria-label={labels.removeFromBag}
            color={isLoading ? 'text-muted' : undefined}
          >
            {labels.removeFromBag}
          </Text>
        </Text>
      </HStack>
    </Alert>
  )
}
