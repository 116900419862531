import { useFormat } from 'helpers/hooks/useFormat'
import { AlertBox } from '../alert-box'

export const LowInStockMessage = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const message = formatMessage({
    id: 'product.lowStock.info',
  })
  return (
    <AlertBox
      description={message}
      rootProps={{
        bg: 'ui.warning.light',
        status: 'warning',
        width: '100%',
      }}
      closeButtonProps={{ display: 'none' }}
      alertDescriptionProps={{ marginLeft: '15px', marginTop: '2px' }}
    />
  )
}
