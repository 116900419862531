import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCartCT } from 'frontastic'
import { SHIPPING_METHODS } from '../constants'

export interface OptionsGetProductProps {
  lineItemId: string
  name: string
  sku: string
  quantity: number
  orderByTime: string
  productPickUpStore: string
  showGetProductOptions: boolean
  isAvailableToday: boolean
}

const changeTheShippingMethod = async (
  lineItemId: string,
  name: string,
  sku: string,
  quantity: number,
  removeItem: any,
  addItem: any,
) => {
  // remove BOPIS line item, this function checks and removes the itemShippingAddress for the cart in the backend
  await removeItem(lineItemId)

  // add the same item with shipping method, this function adds the item by increasing the qty if an item exists already in the cart
  await addItem(sku, quantity, {
    productTitle: name,
    shippingMethod: SHIPPING_METHODS.SHIPPING,
    pickUpStoreId: undefined,
    giftCardAmount: 0,
  })
}

export const OptionsGetProduct = ({
  lineItemId,
  name,
  sku,
  quantity,
  orderByTime,
  productPickUpStore,
  showGetProductOptions,
  isAvailableToday,
}: OptionsGetProductProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { removeItem, addItem } = useCartCT()

  const message = isAvailableToday
    ? formatMessage({ id: 'pdp.store.pickup.order.by.time', values: { time: '2pm' } })
    : formatMessage({ id: 'pdp.store.pickup.order.for.next.day' })

  return (
    <Box fontSize="12px">
      <Text color={!showGetProductOptions ? 'text.muted' : ''}>
        {formatMessage({ id: 'cart.options.store.pickup', values: { location: productPickUpStore } })}
      </Text>
      {showGetProductOptions && (
        <>
          <Text color="text.muted">{message}</Text>
          <Text
            marginTop="2px"
            onClick={() => changeTheShippingMethod(lineItemId, name, sku, quantity, removeItem, addItem)}
            decoration="underline"
            cursor={'pointer'}
          >
            {formatMessage({ id: 'cart.options.move.to.shipping' })}{' '}
          </Text>
        </>
      )}
    </Box>
  )
}
